@import "../../styles/colors.scss";

.landing-page {
    .title {
        // font-size: calc(3rem + 4.5vw);
        line-height: 0.96;
        @media (min-width: $breakpoint-xxl) {
            font-size: calc(2.9rem + 4.5vw);
        }
    }
    .outer-container {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    @media (max-width: $breakpoint-md) {
        .outer-container {
            backdrop-filter: blur(10px);
        }
        .right-half {
            display: none;
        }
    }
}

// @media (min-width: $breakpoint-md) {
//     .border-md-end {
//         border-right: 2px solid $color-black-10;
//     }
// }
// @media (min-width: $breakpoint-lg) {
//     .border-lg-end {
//         border-right: 2px solid $color-black-10;
//     }
// }
// @media (min-width: $breakpoint-xl) {
//     .border-xl-end {
//         border-right: 2px solid $color-black-10;
//     }
// }
