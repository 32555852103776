@import "../../styles/colors.scss";

#navbar-content {
    display: none;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    // position: fixed;
    width: 100vw;
    left: 0;
    top: 50px;
    #navbar-content-items {
        align-content: center;
    }
}
#navbar-content-mobile {
    // display: none;
    color: $light;
    z-index: -1;
    // height: 0;
    transition: top 0.5s;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: -120vh;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    &.show {
        top: 0;
        opacity: 1;
        height: 100vh;
    }
}

#navbar-topbar {
    // z-index: 100;
}

@media (min-width: $breakpoint-lg) {
    #navbar-content {
        flex-direction: row;
        flex-grow: 1;
        display: flex;

        position: inherit;
        width: auto;
    }
    #navbar-content-mobile,
    #navbar-content-mobile.show {
        display: none;
    }
    #navbar-content-items {
        display: flex;
    }
    #navbar-button {
        display: none;
    }
}
