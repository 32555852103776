@import "../../styles/colors.scss";
.preset-viewer-component {
    @media (min-width: $breakpoint-md) {
        .border-md-end {
            border-right: 2px solid $color-black-10;
        }
    }
    @media (min-width: $breakpoint-lg) {
        .border-lg-end {
            border-right: 2px solid $color-black-10;
        }
    }
    @media (min-width: $breakpoint-xl) {
        .border-xl-end {
            border-right: 2px solid $color-black-10;
        }
    }
}
