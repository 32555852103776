// $primary: #267e7e;
// $primary-light: #00b9b9;
// $dark: #312f2e;

// // v1
// $light: #f5f2eb;
// $light-extra: #fcf9f4;

// v2
// $primary: #55B1B5;
// High contrast
$primary: #4d9da1;
$primary-light: #55b1b5;
$primary-dark: #338f94;

// v3
$primary: #3b969b;
$primary-light: #55b1b5;
$primary-dark: #338f94;

$dark: #272932;
$light: #f8f9fa;

$color-yamaha: #fff6e9;
$color-yamaha-dark: #dcd8cd;

$light-extra: #fcfcfc;
$danger: #f2544a;

$color-black-10: rgba(#000, 0.1);
$color-black-20: rgba(#000, 0.2);
$color-black-30: rgba(#000, 0.3);
$color-black-40: rgba(#000, 0.4);
$color-black-50: rgba(#000, 0.5);
$color-black-60: rgba(#000, 0.6);
$color-black-70: rgba(#000, 0.7);
$color-black-80: rgba(#000, 0.8);
$color-black-90: rgba(#000, 0.9);
$color-black-100: rgba(#000, 1);

$color-facebook: #4267b2;
$color-google: #db4437;

$color-red-light: #ffe7ea;

// $color-google: #;

// Old
// $light-extra: #f6f0e3;
// $light: #f5e4ca;

// $secondary: #f5e4ca;
// @import "../../node_modules/bootstrap/scss/bootstrap.scss";

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1452px;
$breakpoint-xxxl: 2160px;
$breakpoint-xxxxl: 3040px;
