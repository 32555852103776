@import "./colors.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";

a.no-styling {
    color: inherit;
    text-decoration: none;
}

html {
    // font-size: 16px;
}

.btn {
    border-radius: 0;
}

.font-display {
    font-family: "Bebas Neue", cursive;
}

body {
    // background-color: #fbf6e1;
    background-color: $light-extra;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

h1 {
    font-family: "Bebas Neue", cursive;
    // font-size: 4.2em;
    font-size: calc(3rem + 1.5vw);
    // line-height: 0.2em;
}

// h1, h2, h3, h4 {
//     letter-spacing: -0.01em;
// }

// p {
//     font-family: "Open Sans", sans-serif;
// }

.footer {
    position: relative;
    bottom: 0;
}

.bg-yamaha {
    background-color: $color-yamaha;
}
.bg-light-extra {
    background-color: $light-extra;
}

// Text weight
.text-300 {
    font-weight: 300;
}
.text-500 {
    font-weight: 500;
}
.text-700 {
    font-weight: 700;
}
.text-800 {
    font-weight: 800;
}
.text-900 {
    font-weight: 700;
}

// Text color
.text-white {
    color: $white;
}

.bg-google {
    background-color: $color-google;
}
.bg-facebook {
    background-color: $color-facebook;
}

.bg-danger-light {
    background-color: $color-red-light;
}

.font-size-125 {
    font-size: 1.25em;
}

.text-medium {
    font-size: calc(1.25rem);
}
.text-large {
    font-size: calc(1.325rem + 0.9vw);
}

// Text size
.text-large-125,
.text-size-125 {
    font-size: 125%;
}
.text-large-150,
.text-size-150 {
    font-size: 150%;
}
.text-extra-large,
.text-large-175 {
    font-size: 175%;
}
.text-size-75 {
    font-size: 75%;
}
.text-size-50 {
    font-size: 50%;
}

.opacity-25 {
    opacity: 0.25;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-75 {
    opacity: 0.75;
}
.opacity-90 {
    opacity: 0.75;
}

.my-height-100 {
    height: calc(100vh);
}

.page-wrapper {
    // margin-top: 5rem;
    padding-top: 5.5rem;
    margin-bottom: 3rem;
}

.container-narrow {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 440px !important;
}

.hover-zoom {
    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
}
.hover-highlight {
    &:hover,
    &:focus {
        color: $primary-light !important;
    }
}

.hover-underline {
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.rounded-4 {
    border-radius: 4rem;
}
.rounded-5 {
    border-radius: 5rem;
}
.rounded-6 {
    border-radius: 6rem;
}
.rounded-7 {
    border-radius: 7rem;
}
.rounded-8 {
    border-radius: 8rem;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-danger:hover,
.btn-danger:hover {
    color: $white !important;
}
.btn-danger {
    color: $white;
}

hr.hr-short {
    width: 64px;
    height: 5px;
}

.image-wrapper {
    width: 100%;
    margin: 1em auto;
    position: relative;
    padding-top: 66.7%;
    overflow: hidden;
    &.square {
        padding-top: 100%;
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        display: block;
        transition: transform 0.3s;
    }
}
