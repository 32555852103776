@import "./colors.scss";

.CircularProgressbar {
    .CircularProgressbar-path {
        stroke: $primary !important;
    }
    // .CircularProgressbar-trail {
    //   stroke: gray;
    // }
    .CircularProgressbar-text {
        fill: $color-black-100 !important;
    }
    // .CircularProgressbar-background {
    //   fill: green;
    // }
}
