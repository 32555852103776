.about-page {
    @media (min-width: 768px) {
        .info-frame {
            display: flex;
            align-items: center;
            .info-frame-text {
                flex-basis: 70%;
            }
            .info-frame-gap {
                flex-basis: 5%;
            }
            .info-frame-img {
                flex-basis: 35%;
            }
        }
    }
}
