@import "../../styles/colors.scss";

.knob-component {
    display: flex;
    position: relative;
}
.knob-component .ticks {
    position: absolute;
}
.knob-component .ticks .tick {
    position: absolute;
    background: $color-yamaha-dark;
    box-shadow: inset 0 0 0 0 black;
    width: 3px;
    transition: box-shadow 0.5s;
}
// Pointer color
.knob-component .ticks .tick.active {
    box-shadow: inset 0 0 5px 2px $primary-light;
}
.knob-component.outer {
    border-radius: 50%;
    border: 1px solid $dark;
    border-bottom: 5px solid $dark;
    /* background-image: radial-gradient(100% 70%, #666 6%, #333 90%); */
    background-color: $dark;
    box-shadow: 0 5px 15px 2px $color-yamaha, 0 0 5px 3px $color-yamaha,
        0 0 0 12px $color-yamaha;
}
.knob-component.inner {
    border-radius: 50%;
}
.knob-component.inner .grip {
    position: absolute;
    width: 5%;
    height: 30%;
    // height: 5%;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: $light-extra;
    // box-shadow: 0 0 3px 1px black;
}
